yQuery(function(){
  var y = yQuery;

  // Early exit if we are not in edit mode
  if (!y.one('#ETEditModal') && !y.one('#EIEditmodal')) return false;


  /**
   *  General functions
   */

  // Get all elements from localStorage
  function getStorageJson(){
    return localStorage.getItem('ETJson');
  }

  // Get element from localStorage
  function getElementFromStorage(category, key){
    var storageJson = getStorageJson();
    if(storageJson == null) return -1;
    return JSON.parse(storageJson).filter(function(item, index){
      return item.key === key &&
          item.category === category;
    });
  }

  // Add element from localStorage
  function putStorage(category, key, value, type, old){
    var storageJson = getStorageJson();
    if(storageJson == null){
      storageJson = JSON.stringify([]);
    }
    var storage = JSON.parse(storageJson);
    var itemStorageLocation = storage.findIndex(function(item, index){
      return item.key === key &&
          item.category === category;
    });
    if (itemStorageLocation > -1){
      storage[itemStorageLocation].value = value;
    } else {
      storage.push({
        category: category,
        key: key,
        value: value,
        type: type,
        old_value: old
      });
    }
    localStorage.setItem('ETJson',JSON.stringify(storage));
  }

  // Remove element from localStorage
  function removeStorage(category, key) {
    var storageJson = getStorageJson();
    if(storageJson == null) return -1;
    var newStorage = JSON.parse(storageJson).filter(function(item, index) {
      return item.key !== key ||
          item.category !== category;
    });
    localStorage.setItem('ETJson',JSON.stringify(newStorage));
  }

  function showLoader() {
    y.one('.et-loader').style.display = 'flex';
  }

  // Disable hover when scrolling
  var body = document.body,timer;
  window.addEventListener('scroll', function() {
    clearTimeout(timer);
    if(!body.classList.contains('disable-hover')) {
      body.classList.add('disable-hover')
    }
    timer = setTimeout(function(){
      body.classList.remove('disable-hover')
    },500);
  }, false);

  // Notification flyout buttons
  y.on('click', y.many('#ETEditNotification .flyout'), function(event){
    var flyout = y.closest('#ETEditNotification', this);
    if(flyout.classList.contains('et-flyout-open')) {
      flyout.classList.remove('et-flyout-open');
    } else {
      flyout.classList.add('et-flyout-open');
    }
  });

  // POST localStorage data to server
  y.on('click', y.one('#ETEditNotification .publish'), function(event){
    showLoader();
    var csrf = y.one('input', y.closest('#ETEditNotification', this)).value;
    y.fetch(this.getAttribute('data-url'),{
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        _token: csrf,
        changes: JSON.parse(getStorageJson())
      })
    }).then(function(response){
      localStorage.removeItem('ETJson');
      window.location.reload();
    });
  });

  // Clear everything from localStorage
  y.on('click', y.one('#ETEditNotification .clear'), function(event){
    localStorage.removeItem('ETJson');
    window.location.reload();
  });

  /**
   *  Editable Text functions
   */
  function getTextModal() {
    var textModal = y.one('#ETEditModal');
    var textModalKey = y.one('.et-key-label', textModal);
    var textModalCategory = y.one('.et-category-label', textModal);
    var textModalOldText = y.one('.et-text-db', textModal);
    var textModalText = y.one('.et-text-area', textModal);
    return {
      element: textModal,
      key: textModalKey,
      category: textModalCategory,
      oldText: textModalOldText,
      textArea: textModalText
    }
  }

  // Get Editable Text element from DOM
  function getET(category, key) {
    return y.one(
        '.et-editable-text[data-key="' + key +
        '"][data-category="' + category +
        '"] .et-text-container'
    );
  }

  // Global-ish text modal variable
  var textModal = getTextModal();

  // Editable Text: onclick open modal
  y.on('click', y.many('.et-edit-text-button'), function(event){
    event.preventDefault();
    event.stopPropagation();
    var text = y.closest('.et-editable-text', this);
    textModal.category.textContent = text.getAttribute('data-category');
    textModal.key.textContent = text.getAttribute('data-key');
    textModal.oldText.innerHTML = y.one('.et-editable-text-old',text).innerHTML;
    textModal.textArea.value = y.one('.et-text-container',text).innerHTML;
  });

  // Editable Text modal: onclick revert
  y.on('click', y.one('#ETEditModal .button.revert'), function(event){
    textModal.textArea.value = textModal.oldText.innerHTML;
    removeStorage(textModal.category.textContent, textModal.key.textContent);
    var text = getET(textModal.category.textContent, textModal.key.textContent);
    text.innerHTML = textModal.oldText.innerHTML;
  });

  // Editable Text modal: onclick preview
  y.on('click', y.one('#ETEditModal .button.preview'), function(event){
    var text = getET(textModal.category.textContent, textModal.key.textContent);
    if (text.innerHTML != textModal.textArea.value) {
      putStorage(
          textModal.category.textContent,
          textModal.key.textContent,
          textModal.textArea.value,
          'text'
      );
      text.innerHTML = textModal.textArea.value;
    }
    y.modal(textModal.element,'close');
  });


  /**
   *  Editable Image functions
   */
      // Editable Image modal
  var imageModal =  {
        element: y.one('#EIEditModal'),
        key: undefined,
        category: undefined,
        initial_src: undefined,
        new_src: undefined,
        target_image: undefined
      };

  // Get Editable Image element from DOM
  function getEI(category, key) {
    return y.one(
        '[data-key="' + key +
        '"][data-category="' + category +
        '"]'
    );
  }

  // Open image modal
  function openImageModal(e) {
    imageModal.target_image = e.srcElement;
    imageModal.category = e.srcElement.getAttribute('data-category');
    imageModal.key = e.srcElement.getAttribute('data-key');
    imageModal.initial_src = e.srcElement.getAttribute('src');
    y.modal('#' + imageModal.element.id, 'open', '#EIEditModal');
  }

  // Read selected file from input and store in localStorage
  function readFileFromInput(event) {
    var input = event.srcElement;
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function(e) {
        imageModal.new_src = e.target.result;
        imageModal.target_image.setAttribute('src', imageModal.new_src);
        putStorage(imageModal.category, imageModal.key, imageModal.new_src, 'image', imageModal.initial_src);
        y.modal('#' + imageModal.element.id, 'close', '#EIEditModal');
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  // Fetch all available editable images
  var editableImages = y.many('img').filter(function (img) {
    return img.hasAttribute('editable-image');
  });

  // Foreach Editable image
  editableImages.forEach(function (img) {
    img.classList.add('et-editable-image');
    img.addEventListener('click', openImageModal);
  });

  // Editable Image input: onchange file input
  y.on('change', imageModal.element.querySelector('input'), readFileFromInput);

  // Editable Image modal: on close clear input
  y.on('yquery.modal.close', imageModal.element, function() {
    imageModal.element.querySelector('input').value = '';
  });

  // Editable Image modal: onclick revert
  y.on('click', y.one('#EIEditModal .button.revert'), function(){
    var storageData = getElementFromStorage(imageModal.category, imageModal.key)[0];
    if (!storageData) {
      return;
    }
    var image = getEI(imageModal.category, imageModal.key);
    image.setAttribute('src', storageData.old_value);
    removeStorage(imageModal.category, imageModal.key);
    y.modal('#' + imageModal.element.id, 'close', '#EIEditModal');
  });

  /**
   * On initial page load: get data from localStorage and update html elements
   */
  var storageJson = getStorageJson();
  if( storageJson != null ) {
    var storage = JSON.parse(storageJson);
    storage.forEach(function(item, index) {
      if (item.type === 'text') {
        var text = getET(item.category, item.key);
        if (text) {
          text.innerHTML = item.value;
        }
      } else if(item.type === 'image') {
        var image = getEI(item.category, item.key);
        if (image) {
          image.setAttribute('src', item.value);
        }
      }
    });
  }
});

/*
 * yQuery-modal 0.0.1
 */
yQuery(function(){
  var modalBg = (function() {
    var ret = document.createElement("div");
    ret.classList.add('yquery-modal-bg','close-yquery-modal');
    ret.style.display = 'none';
    ret.id = 'yquery-modal-bg';
    yQuery.one('body').appendChild(ret);
    return ret;
  })();

  // Extend yQuery object
  yQuery.animations = {
    fadeOut: function(element){
      element.style.opacity = 1;

      (function fade() {
        if ((element.style.opacity -= .1) < 0) {
          element.style.display = "none";
        } else {
          requestAnimationFrame(fade);
        }
      })();
    },
    fadeIn: function(element, display){
      element.style.opacity = 0;
      element.style.display = display || "block";

      (function fade() {
        var val = parseFloat(element.style.opacity);
        if (!((val += .1) > 1)) {
          element.style.opacity = val;
          requestAnimationFrame(fade);
        }
      })();
    }
  };

  yQuery[ 'modal' ] = function(selector, action, origin) {
    var modal = yQuery.one(selector);
    modal.style.top = '100px';
    modal.style.visibility = 'visible';

    if(action === "open") {
      modal.classList.add('open');
      modalBg.setAttribute('data-modal',selector);
      yQuery.animations.fadeIn(modalBg);
      yQuery.animations.fadeIn(modal);
      modal.dispatchEvent(new CustomEvent(
          'yquery.modal.open',
          { detail: { caller: origin } }
      ));
    } else if (action === "close") {
      modal.classList.remove('open');
      yQuery.animations.fadeOut(modalBg);
      yQuery.animations.fadeOut(modal);
      modal.dispatchEvent(new CustomEvent(
          'yquery.modal.close',
          { detail: { caller: origin } }
      ));
    }
  }

  // Attach modals based on .{open|close}-y-modal classes
  yQuery.on('click', yQuery('.open-yquery-modal'), function(event){
    event.preventDefault();
    var modalSelector = this.getAttribute('data-modal');
    if(!modalSelector){
      console.error('yQuery-modal: Data-modal property not defined.');
      return;
    }
    modalBg.setAttribute('data-modal',modalSelector);
    yQuery.modal(modalSelector, 'open', this);
  });

  yQuery.on('click', yQuery('.close-yquery-modal'), function(event){
    event.preventDefault();
    var modalSelector = this.getAttribute('data-modal');
    if(!modalSelector){
      console.error('yQuery-modal: Data-modal property not defined.');
      return;
    }
    yQuery.modal(modalSelector, 'close', this);
  });
});
